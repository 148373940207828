<template>
  <!-- 选择病例 -->
  <div class="select_case">
    <div v-for="(item,index) in caseList">
      <van-cell class="case_box" @click="ChooseCase()">
        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <div class="case_msg">
            <span>{{item.patient.name}}/{{item.patient.id}}</span>
            <span>{{item.scheme.name}}/{{item.subscheme.name}}</span>
          </div>
        </template>
        <template #right-icon>
          <!-- <van-icon name="search" class="search-icon" /> -->
          <div class="num_box">
            {{index + 1}}
          </div>
        </template>
      </van-cell>
  </div>
   
    <!-- 按钮 -->
    <div class="btn">
        <van-button round type="info" size="large" class="confirm_btn" @click="Confirm()">确认</van-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Dialog,Toast } from 'vant';
import {
  recordPass
} from '@/api/api'
import {
    mapState,mapActions
} from 'vuex'

// 全局注册
Vue.use(Dialog);
Vue.use(Toast);
export default {
  name:"",

  data(){
    return {
      caseList:[],
    }
  },
  
  computed:{
    ...mapState(['userId','subcase_id']),
  },
  mounted() {
    this.init()
  },
  methods:{
    ...mapActions(['SET_SUBCASE_ID']),
    ChooseCase(){
      this.$router.push({
        path:"choose_template"
      })
      
    },
    // 确认
    Confirm(){
      
    },
    async init() {
      if(this.$route.params.subproject_id) {
        this.SET_SUBCASE_ID(this.$route.params.subproject_id)
      }
      console.log(this.subcase_id,"iididii")
      await recordPass({params:{
        doctor_id: this.userId,
        expand: 'doctor,patient,scheme,subscheme,nextRecord,canNextRecord',
        subproject_id: this.subcase_id,
        audit_status: '审核通过',
      }}).then(res => {
        this.caseList = res.items;
        console.log(this.caseList,"case",res.items)
      }).catch(err => {
        Toast(err.response.data.message);
      })
    }
    
  },
}
</script>

<style lang="scss" scoped>
.select_case{
  padding: 10px 15px 0;
  .case_box{
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
    margin-bottom: 15px;
    .case_msg{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 14px;
    }
    .num_box{
      // padding: 10px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #BBBBBB;
      // background: #ccc;
      font-size: 14px;
      color: #101010;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .btn{
    .van-button__text{
      font-size: 20px;
    }
    .confirm_btn{
      width: 90%;
      height: 43px;
    }
  }
  .ipt_box{
    width:90%;
    border: 1px solid #BBBBBB;
    padding: 5px;
    margin: 32px auto;
  }
}
</style>